import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export type taskDropDownTypeOptions = {
  id: string;
  label: string;
  value: string;
};

let initialState = {
  taskDropDown: [] as taskDropDownTypeOptions[]
};

export const taskSlice = createSlice({
  name: 'tasks',
  initialState: initialState,
  reducers: {
    addTaskDropDown: (
      state,
      action: PayloadAction<taskDropDownTypeOptions[]>
    ) => {
      state.taskDropDown = action.payload;
    }
  }
});

export const { addTaskDropDown } = taskSlice.actions;
export const taskReducer = taskSlice.reducer;
