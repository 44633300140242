import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { SidebarContext } from 'src/providers/SidebarContext';

interface sidebarmenuprops {
  option: sidebarprop[];
  componentTour?: any;
}

export interface sidebarprop {
  pathname: string;
  subpathname: string;
  icon: JSX.Element;
  submenuname: string;
}
export const SidebarMenus = ({ option, componentTour }: sidebarmenuprops) => {
  const { closeSidebar } = useContext(SidebarContext);
  const { t } = useTranslation('sidebarmenu');
  const location = useLocation();

  useEffect(() => {
    let element;
    if (!componentTour?.tourStatus) {
      element = document.getElementsByClassName('highlight');
    } else {
      element = document.getElementsByClassName('active');
    }
    element[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [location, componentTour]);

  return (
    <Box className="MenuWrapper ">
      <List component="div">
        <Box className="SubMenuWrapper">
          <List component="div">
            {option.map((item, index) => {
              return (
                <ListItem
                  component="div"
                  className={`menuElement ${
                    item?.submenuname === 'My Docs' ? 'highlight' : ''
                  }`}
                  key={item.subpathname}
                >
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={`/${item.pathname}/${item.subpathname}`}
                    startIcon={item.icon}
                  >
                    {t(item.submenuname)}
                  </Button>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </List>
    </Box>
  );
};
