import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCommon } from 'src/modules/common/services/common.service';
import { SidebarMenus } from 'src/shared/components/index';
import * as ROUTES from 'src/shared/constants/routes';
import { SIDEBAR_MENU } from './utils';

export const PARTNER_ADMIN = [
  {
    pathname: ROUTES.BASEPATH,
    subpathname: ROUTES.COMPANIES,
    icon: <Box className="icon-company font-24" />,
    submenuname: 'companies'
  }
];

const SidebarMenu = ({ componentTour }) => {
  const { userData } = useSelector(selectCommon);
  const [userType, setUserType] = useState([]);

  const generateSideBarOptions = (role) => {
    if (role) {
      const options = SIDEBAR_MENU[role];
      const sideBarMenu = options.map((ele) => {
        return { ...ele, icon: <Box className={`${ele.iconLabel} font-24`} /> };
      });
      setUserType(sideBarMenu);
    }
  };

  useEffect(() => {
    generateSideBarOptions(userData?.role);
  }, [userData]);

  return <SidebarMenus option={userType} componentTour={componentTour} />;
};

export default SidebarMenu;
