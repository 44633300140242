import { ORGANIZATION_ROLE_ENUM } from 'src/shared/constants/constants';
import {
  PARTNER_ADMIN,
  PRIMARY_ADMIN,
  REGVERS_ADMIN,
  SECONDARY_ADMIN,
  USER
} from './constants';

export const SIDEBAR_MENU = {
  [ORGANIZATION_ROLE_ENUM.ADMINISTRATION_ADMIN]: REGVERS_ADMIN,
  [ORGANIZATION_ROLE_ENUM.PARTNER_ADMIN]: PARTNER_ADMIN,
  [ORGANIZATION_ROLE_ENUM.BUSINESS_PRIMARY_ADMIN]: PRIMARY_ADMIN,
  [ORGANIZATION_ROLE_ENUM.BUSINESS_SECONDARY_ADMIN]: SECONDARY_ADMIN,
  [ORGANIZATION_ROLE_ENUM.REGVERS_ADMIN_LOGIN_AS]: PRIMARY_ADMIN,
  [ORGANIZATION_ROLE_ENUM.PARTNER_ADMIN_LOGIN_AS]: PRIMARY_ADMIN,
  [ORGANIZATION_ROLE_ENUM.USER]: USER
};
